import { render, staticRenderFns } from "./LampMtap1V1ControllerTelemetryCard.vue?vue&type=template&id=f01082f4&scoped=true&"
import script from "./LampMtap1V1ControllerTelemetryCard.vue?vue&type=script&lang=ts&"
export * from "./LampMtap1V1ControllerTelemetryCard.vue?vue&type=script&lang=ts&"
import style0 from "./LampMtap1V1ControllerTelemetryCard.vue?vue&type=style&index=0&id=f01082f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f01082f4",
  null
  
)

export default component.exports